<template>
  <div class="donate fill-height">
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        justify="center"
        align="center"
        class="fill-height"
      >
        <v-col cols="12" md="8" xs="10">
          <p>
            Please consider donating to support our work.
            We have big plans to extend the magnitude of our analysis, but require serious computing power to do so! :)
          </p>
          <p>
            btc: {{btcAddress}}
            <v-icon class="ml-1" small @click="copyBtcAddress">mdi-content-copy</v-icon>
            <span v-if="showCopiedNotification" class="ml-2 green--text">copied</span>
          </p>
        </v-col>
        <v-col md="8">

        </v-col>
      
      </v-row>
    </v-container>
  </div>
</template>

<script>

  export default {
    name: 'Donate',
    data: () => ({
      btcAddress: '12RWzfxcKjoKxvsnS4pK4THCyhBzRwpEbh',
      showCopiedNotification: false
    }),
    methods: {
      copyBtcAddress () {
        navigator.clipboard.writeText(this.btcAddress)
        this.showCopiedNotification = true
        setTimeout(function () {
          this.showCopiedNotification = false
        }.bind(this), 2000)
      }
    }
  }
</script>
